.settingsContainer {
    
    margin-top: 100px;
    max-width: 600px;
   margin-left: 350px;
   
  
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .settingItem {
      
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
  
      span {
        color: black;
        font-size: 18px;
      }
  
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }
  
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
  
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
      }
  
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
      }
  
      input:checked + .slider {
        background-color: var(--theme-color, #007bff); /* Active color */
      }
  
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
  
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
  
      .slider.round:before {
        border-radius: 50%;
      }
  
      input[type='color'] {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
      }
  
      select {
        padding: 8px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  
    /* Dark mode styles */
    body.dark-mode {
      background-color: #333;
      color: hwb(0 100% 0%);
    }
  }