.language-page-container {
    margin: 20px;
    margin-left: 120px; /* Adjust margin to account for sidebar width */
    margin-top: 100px; /* Adjust margin to account for topbar height */
    padding: 20px;
    border-radius: 12px; /* Rounded container */
    background-color: #ffffff; /* Light background color */
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  
    h1 {
      font-size: 32px; /* Larger font size for headings */
      margin-bottom: 20px; /* Increased bottom margin */
      color: #333333; /* Dark text color */
    }
  
    .language-dropdown {
      color: black;
      width: 100%; /* Full width for dropdown */
      max-width: 300px; /* Limit dropdown width */
      margin: 0 auto; /* Center the dropdown */
      
      .MuiFormControl-root {
        width: 100%;
      }
  
      .MuiInputLabel-root {
        color: #333333; /* Label text color */
        font-size: 18px; /* Label font size */
      }
  
      .MuiSelect-root {
        color: #333333; /* Selected option text color */
        font-size: 18px; /* Selected option font size */
        padding: 12px; /* Padding for selected option */
      }
  
      .MuiMenuItem-root {
        font-size: 16px; /* Font size for dropdown items */
      }
    }
  }
  
  /* Gradient Background */
  body {
    background: linear-gradient(to right, #2980b9, #6dd5fa); /* Gradient background for the body */
    /* Adjust gradient colors as needed */
  }