.userA {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .accounts {
      width: 100%;
      max-width: 800px;
      margin-top: 20px;
  
      .account {
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: white;
        border-radius: 8px;
        margin-bottom: 15px;
  
        .profilePicContainer {
          flex-shrink: 0;
          margin-right: 15px;
  
          .profilePic {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
  
        .accountInfo {
          p {
            margin: 0;
            font-size: 16px;
            color: #006400;
          }
  
          .disable {
            margin-top: 10px;
            margin-left: 5px;
            padding: 8px 15px;
            border: none;
            border-radius: 5px;
            background-color: #ff0000;
            color: #fff;
            cursor: pointer;
            transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
            &:hover {
              background-color: #0056b3;
              transform: translateY(-2px);
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
            }
          }
          .approve {
            margin-top: 10px;
            margin-left: 5px;
            padding: 8px 15px;
            border: none;
            border-radius: 5px;
            background-color: #006400;
            color: #fff;
            cursor: pointer;
            transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
            &:hover {
              background-color: #0056b3;
              transform: translateY(-2px);
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
            }
          }
          .terminte {
            margin-top: 10px;
            margin-left: 5px;
            padding: 8px 15px;
            border: none;
            border-radius: 5px;
            background-color: gray;
            color: #000000;
            cursor: pointer;
            transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
            &:hover {
              background-color: #0056b3;
              transform: translateY(-2px);
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
  }