.search-page-container {
    margin: 20px;
    margin-left: 120px;
    margin-top: 100px; /* Adjust margin to account for sidebar width */
    padding: 20px;
    border-radius: 12px; /* Increased border radius for a softer look */
    background-color: #ffffff; /* Light background color */
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  
    h1 {
      font-size: 36px; /* Larger font size for headings */
      margin-bottom: 20px; /* Increased bottom margin */
      color: #333333; /* Dark text color */
    }
  
    .search-form {
      margin-bottom: 30px; /* Increased bottom margin */
  
      input[type="text"] {
        width: 800px;
        padding: 16px; /* Larger padding for input */
        border: 1px solid #e0e0e0; /* Light border color */
        border-radius: 12px;
        font-size: 18px; /* Font size for input */
        outline: none;
        transition: border-color 0.3s ease;
  
        &:focus {
          border-color: #007bff; /* Focus border color */
        }
      }
  
      button {
        margin-left: 20px;
        padding: 16px 28px; /* Larger padding for button */
        border: none;
        border-radius: 12px;
        background-color: #007bff; /* Primary button color */
        color: #ffffff; /* White text color */
        font-size: 18px; /* Font size for button */
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s ease;
  
        &:hover {
          background-color: #0056b3; /* Darker hover color */
          transform: translateY(-3px); /* Enhanced hover effect */
        }
      }
    }
  
    .search-results {
      padding: 20px;
      border-radius: 12px;
      background-color: #f5f5f5; /* Light gray background for results */
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  
      h2 {
        font-size: 32px; /* Larger font size for headings */
        margin-bottom: 15px;
        color: #333333; /* Dark text color */
      }
  
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
  
        li {
          margin-bottom: 10px;
  
          a {
            text-decoration: none;
            color: #007bff; /* Link color */
            font-size: 18px; /* Font size for links */
            transition: color 0.3s ease;
  
            &:hover {
              color: #0056b3; /* Darker hover color */
            }
          }
        }
      }
    }
  }
  
  /* Gradient Background */
  body {
    background: linear-gradient(to right, #fffff3, #fff4f3); /* Gradient background for the body */
    /* Adjust gradient colors as needed */
  }