.lateVid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  background-color: #f0f0f0;

  .lateVidContent {
    max-width: 1000px;
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    h2 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 28px;
      color: #333;
      text-transform: uppercase;
    }



    .videoContainer {
      position: relative;
      margin-bottom: 50px;

      video {
        width: 450px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }

      .videoInfo {
        margin-top: 15px;
        color: #333;
        font-size: 16px;

        p {
          margin: 5px 0;
        }
      }
      .know{
        display: flex;

        .actionButtons{
          margin-top: 45px;
          margin-left: 500px;
        }
      }
    }
  }
}
