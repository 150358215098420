.home {
  display: flex;
  justify-content: center; /* Center the container horizontally */
  margin-top: 50px; /* Add space from the top */
  
  .homeContainer {
      margin-top: 25px;
    max-width: 700px;
    max-height: 820px;
    width: 800px; /* Ensure the container takes full width */
    padding-top: 30px;
    border-radius: 10px;
    background-color: #c0acac;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content vertically */
    gap: 30px; /* Add space between elements */

    h2 {
      font-size: 24px;
      color: #333;
      text-transform: uppercase;
      margin: 0; /* Remove default margin */
    }

    .accountForm {
      display: flex;
      flex-direction: column;
      width: 600px; /* Ensure form takes full width */

      .formGroup {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        

        label {
          font-weight: bold;
          margin-bottom: 10px;
          color: #555;
          text-transform: uppercase;
          font-size: 14px;
        }

        input[type="text"],
        input[type="number"],
        input[type="tel"],
        input[type="password"],
        input[type="email"],
        select,
        input[type="file"] {
          width: 100%;
          padding: 15px;
          border: none;
          border-radius: 8px;
          background-color: #fff;
          font-size: 16px;
          color: #333;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          transition: background-color 0.3s, box-shadow 0.3s;

          &:focus {
            background-color: #f0f0f0;
          }

          &::placeholder {
            color: #aaa;
          }
        }

        select {
          appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 15px center;
          background-size: 20px;
          padding-right: 40px;
        }

        
      }
      .but {
        margin-left: 170px;
        height:50px;
        width: 200px;
       margin-top: 15px;/* Increase padding */
        border: none;
        border-radius: 25px; /* Make button more round */
        background-color: #007bff;
        background-image: linear-gradient(to right, #007bff, #00bcd4);
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
        &:hover {
          background-color: #0056b3;
          transform: translateY(-2px);
          box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
        }
      }
    }
   
  }
}
