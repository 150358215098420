.analytics-chart {
    width: 100%;
    max-width: 800px; /* Adjust as needed */
    margin: 120px auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
  
    canvas {
      width: 100% !important; /* Ensure chart canvas fills its container */
      height: 400px; /* Set a fixed height for the chart */
    }
  
    h2 {
      margin: 20px;
      font-size: 24px;
      color: #333;
      text-align: center;
    }
  
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }