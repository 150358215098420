.account{
  // AccountPage.scss

.account-page-container {
  margin: 20px;
  margin-left: 320px;
  margin-top: 100px;
  padding: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
 
  h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333333;
  }

  .account-details {
    .account-item {
      margin-bottom: 15px;
      padding: 12px;
      border-radius: 8px;
      background-color: #f5f5f5;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 500px;
      p {
        margin: 0;
        font-size: 16px;
        color: #333333;
      }

      .update-btn {
        padding: 10px 20px;
        border: none;
        border-radius: 6px;
        background-color: #007bff;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}
}