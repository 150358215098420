// letblog.scss
.blo{
  .container1 {
    
    margin-top: 50px;
    padding: 20px;

    h2{
      text-align: center;
      font-size: 35px;
    }
  }
  
  .blog {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .blog h3 {
    color: black;
    margin-top: 200px;
    font-size: 24px;
    
  }
  
  .blog p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  
}
