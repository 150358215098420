.notifications-page-container {
    margin: 20px;
    margin-left: 320px; /* Adjust margin to account for sidebar width */
    margin-top: 100px; /* Adjust margin to account for topbar height */
    padding: 20px;
    border-radius: 12px; /* Rounded container */
    background-color: #ffffff; /* Light background color */
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  
    h1 {
      font-size: 32px; /* Larger font size for headings */
      margin-bottom: 20px; /* Increased bottom margin */
      color: #333333; /* Dark text color */
    }
  
    .notifications-list {
      .notification-item {
        margin-bottom: 15px;
        padding: 12px;
        border-radius: 8px;
        background-color: #f5f5f5; /* Light gray background */
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  
        p {
          margin: 0;
          font-size: 16px;
          color: #333333; /* Dark text color */
        }
  
        .notification-time {
          font-size: 14px;
          color: #666666; /* Medium gray text color */
        }
      }
    }
  }
  
  /* Gradient Background */
 