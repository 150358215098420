// UserVideos.scss

.userVideosContainer {
    padding: 20px;
  }
  
  .videoCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .videoPlayer {
    margin-bottom: 10px;
  }
  
  .videoDetails {
    margin-bottom: 10px;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .approveButton, .declineButton {
    margin-left: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .approveButton {
    background-color: #4CAF50;
    color: white;
  }
  
  .declineButton {
    background-color: #f44336;
    color: white;
  }
  
  .approveButton:hover, .declineButton:hover {
    background-color: darken(#4CAF50, 10%);
  }
  